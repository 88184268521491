import { makeStyles } from '@material-ui/core/styles';

const colorOrange = '#FF8763';

const useStyles = makeStyles((theme) => ({
    loginContainer: {
        [theme.breakpoints.up('sm')]: {
            margin: '0 auto',
            width: '100%',
            height: '100%',
            position: 'relative',
        },
    },
    textTitle: {
        fontSize: 20,
        color: '#747474',
        textAlign: 'center',
        marginBottom: 60,
    },
    textInput: {
        width: '100%',
    },
    formField: {
        padding: 0,
        paddingBottom: 20,
        position: 'relative',
        color: '#E0E0E0',
    },
    headerLogin: {
        [theme.breakpoints.down('xs')]: {
            width: 296,
            margin: '20px auto 0',
        },
    },
    containLeft: {
        [theme.breakpoints.up('sm')]: {
            width: '48%',
            float: 'left',
            marginTop: 26,
            marginLeft: 21,
        },
    },
    containRight: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            width: '49%',
            float: 'right',
            height: '100%',
        },
    },
    rightImg: {
        width: '100%',
        objectFit: 'cover',
        [theme.breakpoints.up('sm')]: {
            height: '100%',
        },
    },
    loginContent: {
        maxWidth: 307,
        [theme.breakpoints.up('sm')]: {
            left: '5%',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        [theme.breakpoints.up('md')]: {
            left: '9%',
        },
        [theme.breakpoints.up('lg')]: {
            left: '15%',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '20% auto',
            maxWidth: 280,
        },
    },
    companyLogo: {
        textAlign:'center',
        marginBottom: 100,
    },
    btnLogin: {
        background: colorOrange,
        borderRadius: 26,
        padding: 6,
        width: '100%',
        '&:hover': {
            background: colorOrange,
        },
    },
    btnLoginText: {
        color: '#FFFFFF',
        padding: '2px 121px',
        letterSpacing: 2,
        fontSize: 20,
    },
    btnTextForgot: {
        display: 'block',
        marginTop: 26,
        textAlign: 'center',
        fontSize: 14,
        color: '#536777',
    },
    btnVendor: {
        marginTop: 20,
        background: 'transparent',
        borderRadius: 7,
        border: `1px solid ${colorOrange}`,
        padding: 6,
        width: '100%',
        '&:hover': {
            background: 'transparent',
        },
    },
    btnVendorText: {
        color: colorOrange,
        letterSpacing: 1,
        fontSize: 20,
        textTransform: 'capitalize',
    },
}));

export default useStyles;
